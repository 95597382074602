<template>
  <div> {{ object }} </div>
</template>

<script>
export default {
    props: {
        object: {type: Object, required: true}
    }
}
</script>

<style>

</style>